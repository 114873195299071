
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import FscAudioPlayer from "@/components/FscAudioPlayer.vue";
import { setupLongPressGesture } from "@/utils/gestureUtils";

@Options({
  components: {
    FscAudioPlayer,
  },
})
export default class MultiChoice extends Vue {
  private readonly LONG_PRESS_DURATION = 500;

  @Prop()
  public question!: any;

  @Prop({ default: false })
  public showCorrectAnswers!: any;

  @Prop({ default: false })
  public disabled!: any;

  private answers: Array<any> = [];
  private options: Array<any> = [];
  private answered = false;

  public mounted() {
    const optionElements = this.$refs.optionGesture as HTMLElement[];

    // Setup long press gestures for each of options
    optionElements.forEach((option) => {
      setupLongPressGesture(option, this.LONG_PRESS_DURATION, () => {
        this.playAudio(`option${option.dataset.position}`);
      });
    });
  }

  private onAnswerChange(value: boolean, position: any): void {
    const answer = this.answers.find((a: any) => a.position == position);
    answer.answer = value;
    answer.correct = answer.value == value;
    this.answered = this.answers.findIndex((a: any) => a.answer) > -1;
    this.answerGiven();
  }

  private playAudio(key: string): void {
    const audioPlayer = this.$refs.audioPlayer as any;
    if (audioPlayer && audioPlayer.question.id) {
      audioPlayer.playAudio(key);
    }
  }

  public isAnswerCorrect(): boolean {
    return this.answers.findIndex((a: any) => !a.correct) === -1;
  }

  private answerGiven(): void {
    this.$emit("onAnswerGiven", {
      given: this.answered,
      answer: this.answers,
      correct: this.isAnswerCorrect(),
    });
  }

  public isAnswerIndexCorrectStyle(answer: any) {
    if (answer) {
      return `visibility: visible; margin-top: 0.6rem;`;
    }
    return `visibility: hidden; margin-top: 0.6rem;`;
  }

  @Watch("question", { immediate: true, deep: true })
  public onQuestionChange(question: any): void {
    if (question) {
      this.options = [...question.options];
      if (question.answer) {
        this.answers = question.answer;
        this.options = this.options.map((option: any) => {
          const value = question.answer.findIndex((answer: any) => answer.position == option.position && answer.answer) > -1;
          option.value = value;
          return option;
        });
      } else {
        this.options = this.options.map((option: any) => {
          option.value = false;
          return option;
        });
        this.answers = this.options.map((option: any) => {
          const answerPositions = question.correctAnswer.split(";");
          const value = answerPositions.indexOf(String(option.position)) > -1;
          const answer = {
            position: option.position,
            value: value,
            correct: !value,
            answer: false,
          };
          return answer;
        });
      }
    }
  }
}
