import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_StandardQuestionExplanation = _resolveComponent("StandardQuestionExplanation")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_VideoQuestion = _resolveComponent("VideoQuestion")!
  const _component_ImageQuestion = _resolveComponent("ImageQuestion")!
  const _component_BaseQuestion = _resolveComponent("BaseQuestion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { cols: "10" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.questionTitle), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_col, {
          cols: "2",
          class: "text-right"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_StandardQuestionExplanation, {
              question: _ctx.question,
              key: _ctx.question.officialNumber
            }, null, 8, ["question"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_b_row, { class: "h-100" }, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { class: "theory-question h-100" }, {
          default: _withCtx(() => [
            (_ctx.question.withVideo)
              ? (_openBlock(), _createBlock(_component_VideoQuestion, {
                  key: 0,
                  question: _ctx.question,
                  onOnShowSolutionButton: _ctx.showSolutionButton,
                  onOnAnswer: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onAnswer($event))),
                  "show-correct-answers": _ctx.showCorrectAnswers
                }, null, 8, ["question", "onOnShowSolutionButton", "show-correct-answers"]))
              : (_ctx.question.withPicture)
                ? (_openBlock(), _createBlock(_component_ImageQuestion, {
                    key: 1,
                    question: _ctx.question,
                    onOnShowSolutionButton: _ctx.showSolutionButton,
                    onOnAnswer: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAnswer($event))),
                    "show-correct-answers": _ctx.showCorrectAnswers
                  }, null, 8, ["question", "onOnShowSolutionButton", "show-correct-answers"]))
                : (_openBlock(), _createBlock(_component_BaseQuestion, {
                    key: 2,
                    question: _ctx.question,
                    onOnShowSolutionButton: _ctx.showSolutionButton,
                    onOnAnswer: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onAnswer($event))),
                    "show-correct-answers": _ctx.showCorrectAnswers
                  }, null, 8, ["question", "onOnShowSolutionButton", "show-correct-answers"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}