import { createGesture } from "@ionic/vue";

export function setupLongPressGesture(element: HTMLElement, duration: number, callback: () => void, gestureName = "long-press"): void {
  let pressTimer: any;
  let longPressTriggered = false;

  const gesture = createGesture({
    el: element,
    gestureName,
    threshold: 0,
    onStart: () => {
      longPressTriggered = false;
      pressTimer = setTimeout(() => {
        longPressTriggered = true;
        callback();
      }, duration);
    },
    onEnd: () => {
      clearTimeout(pressTimer);
      // If the long press gesture is triggered then prevent other events
      if (longPressTriggered) {
        element.addEventListener(
          "click",
          (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          { once: true }
        );
      }
    },
  });

  gesture.enable();
}
