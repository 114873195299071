
import { Options } from "vue-property-decorator";
import { Vue } from "vue-class-component";
import FscAudioPlayer from "@/components/FscAudioPlayer.vue";
import { PropType } from "vue";
import { IQuestion } from "@/interfaces/IQuestion";
import { setupLongPressGesture } from "@/utils/gestureUtils";

@Options({
  components: { FscAudioPlayer },
  props: {
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    },
  },
})
export default class StandardQuestionExplanation extends Vue {
  private readonly LONG_PRESS_DURATION = 500;
  private readonly EXPLANATION_AUDIO_KEY = "explanation";

  public isPlaying = false;
  public explanationExists = false;

  public mounted(): void {
    const audioPlayer = this.$refs.audioPlayer as any;
    if (audioPlayer && audioPlayer.question.officialNumber) {
      const explanationUrl = audioPlayer.audioUrls["explanation"];
      audioPlayer.fetchAudioBlobUrl(explanationUrl).then((url: any) => {
        this.explanationExists = url !== "";
        if (this.explanationExists) {
          this.setupGestures();
        }
      });
    }
  }

  public setIsPlaying(isPlaying: boolean): void {
    this.isPlaying = isPlaying;
  }

  public playAudio(key: string): void {
    const audioPlayer = this.$refs.audioPlayer as any;
    if (audioPlayer) {
      audioPlayer.playAudio(key);
    }
  }

  public pauseAudio(): void {
    const audioPlayer = this.$refs.audioPlayer as any;
    if (audioPlayer && audioPlayer.pauseAudio) {
      audioPlayer.pauseAudio();
    }
  }
  private setupGestures(): void {
    const pausePlayingExplanationImage = this.$refs.pausePlayingExplanationImage as HTMLElement;
    if (pausePlayingExplanationImage) {
      setupLongPressGesture(pausePlayingExplanationImage, this.LONG_PRESS_DURATION, () => {
        const audioPlayer = this.$refs.audioPlayer as any;
        if (audioPlayer) {
          audioPlayer.stopCurrentAudio();
          audioPlayer.playAudio(this.EXPLANATION_AUDIO_KEY);
        }
      });
    }
  }
}
