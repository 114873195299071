import localForage from "localforage";
import { STORAGE_IMAGE_KEY } from "@/utils/localForageUtil";
import { setup } from "axios-cache-adapter";
// @ts-ignore
import memoryDriver from "localforage-memoryStorageDriver";

export default class AxiosCache {
  private static _instance: AxiosCache;

  private cacheExpireMin = 60; // 60 min.

  public _axios: Promise<any>;

  private constructor() {
    this._axios = this.setupAxios();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  private async setupAxios() {
    await localForage.defineDriver(memoryDriver);
    const forageStore = localForage.createInstance({
      driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE, memoryDriver._driver],
      name: STORAGE_IMAGE_KEY,
    });

    return setup({
      cache: {
        readHeaders: false,
        maxAge: this.cacheExpireMin * 60 * 1000,
        exclude: {
          query: false,
        },
        store: forageStore,
      },
      baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
    });
  }
}
