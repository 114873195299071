
import { Options, Prop, Vue } from "vue-property-decorator";
import VideoQuestion from "@/components/Pages/TheoryQuestions/Questions/VideoQuestion.vue";
import ImageQuestion from "@/components/Pages/TheoryQuestions/Questions/ImageQuestion.vue";
import BaseQuestion from "@/components/Pages/TheoryQuestions/Questions/BaseQuestion.vue";
import { KNOWLEDGE_AREA_MAPPER } from "@/maps/KnowledgeAreaMapper";
import { toRaw } from "vue";
import isEmpty from "lodash/isEmpty";
import StandardQuestionExplanation from "@/components/Pages/Parts/StandardQuestionExplanation.vue";

@Options({
  components: { StandardQuestionExplanation, BaseQuestion, ImageQuestion, VideoQuestion },
  emits: ["onShowSolutionButton", "onAnswer"],
})
export default class Question extends Vue {
  @Prop()
  public question!: any;

  @Prop()
  public showCorrectAnswers!: any;

  @Prop()
  public studentEducation!: any;

  private get knowledgeAreaMap(): Record<string, string> {
    return KNOWLEDGE_AREA_MAPPER;
  }

  private showSolutionButton(): void {
    this.$emit("onShowSolutionButton");
  }

  private onAnswer(answerData: any): void {
    this.$emit("onAnswer", answerData);
  }

  public get questionTitle(): string {
    const rawQuestion = toRaw(this.question);

    if (isEmpty(rawQuestion)) {
      return "";
    }

    return `${rawQuestion.officialNumber ? `${this.knowledgeAreaMap[rawQuestion.officialNumber.substr(0, 3)]} - ` : ""}  ${
      rawQuestion.officialNumber
    }  -  ${this.$tc("general.point", 2)} : ${rawQuestion.points}`;
  }
}
