import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/lms/fragentutor_standard_compressed.png'
import _imports_1 from '@/assets/img/lms/fragentutor_active_compressed.png'


const _hoisted_1 = { class: "audio-control" }
const _hoisted_2 = {
  ref: "playPlayingExplanationImage",
  class: "pointer play-button",
  src: _imports_0,
  alt: "Play button"
}
const _hoisted_3 = {
  ref: "pausePlayingExplanationImage",
  class: "pointer pause-button",
  src: _imports_1,
  alt: "Pause button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FscAudioPlayer = _resolveComponent("FscAudioPlayer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", null, [
      (_openBlock(), _createBlock(_component_FscAudioPlayer, {
        ref: "audioPlayer",
        question: _ctx.question,
        onAudioStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setIsPlaying(true))),
        onAudioResume: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIsPlaying(true))),
        onAudioPause: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setIsPlaying(false))),
        onAudioEnded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setIsPlaying(false))),
        key: _ctx.question.id
      }, null, 8, ["question"])),
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.playAudio(this.EXPLANATION_AUDIO_KEY)))
        }, [
          _createElementVNode("img", _hoisted_2, null, 512)
        ], 512), [
          [_vShow, !_ctx.isPlaying]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.pauseAudio && _ctx.pauseAudio(...args)))
        }, [
          _createElementVNode("img", _hoisted_3, null, 512)
        ], 512), [
          [_vShow, _ctx.isPlaying]
        ])
      ])
    ], 512), [
      [_vShow, _ctx.explanationExists]
    ])
  ]))
}