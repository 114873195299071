
import { Options, Prop, Vue } from "vue-property-decorator";
import emitter from "@/utils/EventBus";
import { IQuestion } from "@/interfaces/IQuestion";
import { PropType } from "vue";

@Options({})
export default class HintButton extends Vue {
  name = "HintButton";

  @Prop({ type: Object as PropType<IQuestion> })
  public question!: IQuestion;

  public active = false;

  public clickHandle() {
    if (this.question?.hint) {
      this.active = !this.active;
      this.$emit("click");
      emitter.$emit("question-hint-toggle");
    }
  }

  public mounted() {
    emitter.$on("question-hint-model", (value: boolean) => {
      if (this.question?.hint) this.active = value;
    });
  }
}
