
import { Options, Watch } from "vue-property-decorator";
import TitleAndAdditionalText from "@/components/Pages/Parts/TitleAndAdditionalText.vue";
import MultiChoice from "@/components/Pages/TheoryQuestions/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryQuestions/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import MediaQuestionMixin from "@/mixins/TheoryQuestions/MediaQuestionMixin";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin.ts";
import ImageLoadingMixin from "@/mixins/Media/ImageLoadingMixin";
import VideoLoadingMixin from "@/mixins/Media/VideoLoadingMixin";
import VideoPlayer from "@/components/VideoPlayer.vue";
import FscImage from "@/components/FscImage.vue";
import { useBackButton } from "@ionic/vue";
import FscModal from "@/components/FscModal.vue";

@Options({
  components: { TitleAndAdditionalText, FscImage, VideoPlayer, FreeText, MultiChoice },
})
export default class VideoQuestion extends mixins(QuestionMixin, MediaQuestionMixin, ModalMixin, ImageLoadingMixin, VideoLoadingMixin) {
  private countSeen = 5;
  private questionsVisible = false;
  private authThumbUrl = "";

  public videoModal = false;
  public startQuestionWarningModal = false;

  private showQuestions(): void {
    this.hideStartQuestionWarningModal(null);
    this.questionsVisible = true;
  }

  private showStartQuestionWarningModal(e: PointerEvent) {
    this.showModal(e, "startQuestionWarningModal");
  }

  private hideStartQuestionWarningModal(e: any | null) {
    this.hideModal(e, "startQuestionWarningModal");
  }

  private showVideo(e: PointerEvent) {
    if (this.countSeen <= 0 || this.imgLoadError) {
      return;
    }
    this.showModal(e, "videoModal");
    this.countSeen--;
  }

  private hideVideo(e: any) {
    this.hideModal(e, "videoModal");
    this.authThumbUrl = this.authEndThumbUrl;
  }

  // TODO make onAnswer chain coherent!
  private onAnswer(correct: boolean): void {
    this.$emit("onAnswer", {
      correct: correct,
      numberOfTimesVideoWasPlayed: 5 - this.countSeen,
    });
  }

  @Watch("question", { immediate: true, deep: true })
  private onQuestionChange(question: Array<any>): void {
    if (question) {
      this.imgLoadError = false;
      this.questionsVisible = false;
      this.countSeen = 5;
      this.authThumbUrl = this.authStartThumbUrl;
    }
  }

  public created() {
    useBackButton(1, (processNextHandler) => {
      const startQuestionWarningModalRef = this.$refs["startQuestionWarningModal"] as FscModal;
      const videoModalRef = this.$refs["videoModal"] as FscModal;

      if (startQuestionWarningModalRef?.showModal || videoModalRef?.showModal) {
        this.onVideoEnded();
      } else {
        processNextHandler();
      }
    });
  }

  private onVideoEnded(): void {
    this.hideStartQuestionWarningModal(null);
    this.hideVideo(null);
  }

  private showImage(e: PointerEvent) {
    this.showModal(e, "imageModal");
  }

  private hideImage(e: PointerEvent) {
    this.hideModal(e, "imageModal");
  }
}
