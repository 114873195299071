import { isRemember } from "@/helper/RememberMe";

export default class StorageService {
  private static getStorage() {
    return isRemember() ? localStorage : sessionStorage;
  }

  public static setToStorage(key: string, data: any): void {
    this.getStorage().setItem(key, JSON.stringify(data));
  }

  public static setObjectToStorage(key: string, data: any): void {
    this.getStorage().setItem(key, data);
  }

  public static getFromStorage(key: string): string | null {
    return this.getStorage().getItem(key);
  }

  public static removeFromStorage(key: string): void {
    this.getStorage().removeItem(key);
  }

  public static listenStorage(callback: any): void {
    window.addEventListener("storage", callback, false);
  }
}
