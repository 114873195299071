import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "image-question-wrapper my-5" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleAndAdditionalText = _resolveComponent("TitleAndAdditionalText")!
  const _component_MultiChoice = _resolveComponent("MultiChoice")!
  const _component_FreeText = _resolveComponent("FreeText")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { class: "d-flex justify-content-center flex-column" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_TitleAndAdditionalText, { question: _ctx.question }, null, 8, ["question"]),
              (_ctx.question.questionType === 'MULTIPLE_CHOICE')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_MultiChoice, {
                      question: _ctx.question,
                      "show-correct-answers": _ctx.showCorrectAnswers,
                      onOnShowSolutionButton: _ctx.showSolutionButton,
                      onOnAnswer: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onAnswer($event)))
                    }, null, 8, ["question", "show-correct-answers", "onOnShowSolutionButton"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.question.questionType === 'FREE_TEXT')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_FreeText, {
                      question: _ctx.question,
                      "show-correct-answers": _ctx.showCorrectAnswers,
                      onOnShowSolutionButton: _ctx.showSolutionButton,
                      onOnAnswer: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAnswer($event)))
                    }, null, 8, ["question", "show-correct-answers", "onOnShowSolutionButton"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}