
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import FscAudioPlayer from "@/components/FscAudioPlayer.vue";
import { setupLongPressGesture } from "@/utils/gestureUtils";
import shuffle from "lodash/shuffle";

@Options({
  components: {
    FscAudioPlayer,
  },
})
export default class MultiChoice extends Vue {
  private readonly LONG_PRESS_DURATION = 500;

  @Prop()
  public question!: any;

  @Prop()
  public showCorrectAnswers!: any;

  private answers: Array<any> = [];
  private options: Array<any> = [];

  public mounted() {
    const optionElements = this.$refs.optionGesture as HTMLElement[];

    // Setup long press gestures for each of options
    optionElements.forEach((option) => {
      setupLongPressGesture(option, this.LONG_PRESS_DURATION, () => {
        this.playAudio(`option${option.dataset.position}`);
      });
    });
  }

  private onAnswerChange(value: boolean, position: any): void {
    const answer = this.answers.find((a: any) => a.position == position);
    answer.correct = answer.value == value;
    this.showSolutionButton();
  }

  private playAudio(key: string): void {
    const audioPlayer = this.$refs.audioPlayer as any;
    if (audioPlayer && audioPlayer.question.id) {
      audioPlayer.playAudio(key);
    }
  }

  public isAnswerCorrect(): boolean {
    return this.answers.findIndex((a: any) => !a.correct) === -1;
  }

  public isAnswerIndexCorrectStyle(answer: any) {
    if (answer) {
      return `visibility: visible; margin-top: 0.6rem;`;
    }
    return `visibility: hidden; margin-top: 0.6rem;`;
  }

  private showSolutionButton(): void {
    this.$emit("onShowSolutionButton");
  }

  @Watch("showCorrectAnswers", { immediate: true, deep: true })
  public onShowCorrectAnswers(toggle: any): void {
    if (true === toggle) {
      this.$emit("onAnswer", this.isAnswerCorrect());
    }
  }

  @Watch("question", { immediate: true, deep: false })
  public onQuestionChange(question: any): void {
    if (question) {
      this.options = [...question.options];
      this.options = shuffle(this.options);
      this.options = this.options.map((option: any) => {
        return {
          ...option,
          value: false,
        };
      });
      this.answers = this.options.map((option: any) => {
        const answerPositions = question.correctAnswer.split(";");
        const value = answerPositions.indexOf(String(option.position)) > -1;
        return {
          position: option.position,
          value: value,
          correct: !value,
        };
      });
    }
  }
}
