
import { computed, defineComponent, PropType, ref } from "vue";
import axios from "@/utils/axios";
import CryptoJS from "crypto-js";
import type { IQuestion } from "@/interfaces/IQuestion";
import type { IOption } from "@/interfaces/IOption";
import { getAudioItem, removeAudioItem, setAudioItem } from "@/utils/localForageUtil";
import UserService from "@/services/UserService";

const FALLBACK_LANGUAGE = "ger";
const UNSUPPORTED_LANGUAGE = "hrv";

// Shared global audio reference to prevent multiple playback at the same time
const globalAudioRef = ref<HTMLAudioElement | null>(null);
// Track currently playing audio in order to prevent playing same audio
const currentAudioKey = ref<string | null>(null);

const user = computed(() => UserService.getUser());

export default defineComponent({
  props: {
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    },
    options: {
      type: Array as PropType<IOption[]>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    audioUrls(): Record<string, string> {
      const urls: Record<string, string> = {
        title: `/theory-questions/${this.question.id}/title-audio`,
        additionalText: `/theory-questions/${this.question.id}/additional-text-audio`,
        explanation: `/standard-question-explanation/${this.question.officialNumber}/audio`,
      };

      this.options?.forEach((option) => {
        urls[`option${option.position}`] = `/theory-questions/${this.question.id}/option/${option.position}/audio`;
      });

      return urls;
    },
  },
  methods: {
    getUserIso639dash1Code(): string {
      return user.value?.language?.iso639dash1Code ?? FALLBACK_LANGUAGE;
    },

    stopCurrentAudio(): void {
      if (globalAudioRef.value) {
        globalAudioRef.value.pause();
        globalAudioRef.value.currentTime = 0;
        this.$emit("audio-stop", currentAudioKey.value);
        globalAudioRef.value = null;
        currentAudioKey.value = null;
      }
    },

    pauseAudio(): void {
      if (globalAudioRef.value) {
        globalAudioRef.value.pause();
        this.$emit("audio-pause", currentAudioKey.value);
      }
    },

    generateStorageKey(input: string): string {
      return `TTS_${CryptoJS.MD5(input).toString()}_${this.getUserIso639dash1Code()}_audio`;
    },

    async fetchAudioBlobUrl(url: string): Promise<string> {
      if (!user.value || this.getUserIso639dash1Code() === UNSUPPORTED_LANGUAGE) {
        return "";
      }

      const storageKey = this.generateStorageKey(url);
      const cachedBlob = await getAudioItem(storageKey);

      if (cachedBlob) {
        return URL.createObjectURL(cachedBlob);
      }

      try {
        const response = await axios.get(url, { responseType: "blob" });
        await setAudioItem(storageKey, response.data);
        return URL.createObjectURL(response.data);
      } catch (error) {
        return "";
      }
    },

    async playAudio(key: string) {
      if (!user.value) {
        this.$toasted.error(this.$t("text_to_speech.feature_not_available_for_guests"));
        return;
      }

      if (this.getUserIso639dash1Code() === UNSUPPORTED_LANGUAGE) {
        this.$toasted.error(this.$t("text_to_speech.language_not_supported"));
        return;
      }

      const audioUrl = this.audioUrls[key];
      if (!audioUrl) return;

      // If the requested audio is already playing then do nothing
      if (currentAudioKey.value === audioUrl && globalAudioRef.value && !globalAudioRef.value.paused) {
        return;
      }

      // If the requested audio is paused then resume it
      if (currentAudioKey.value === audioUrl && globalAudioRef.value && globalAudioRef.value.paused) {
        try {
          await globalAudioRef.value.play();
          this.$emit("audio-resume", key);
          return;
        } catch (error) {
          console.error("Error resuming audio:", error);
          const storageKey = this.generateStorageKey(audioUrl);
          await removeAudioItem(storageKey);
        }
      }

      // Otherwise start playing from the beginning
      const blobUrl = await this.fetchAudioBlobUrl(audioUrl);
      if (!blobUrl) return;

      this.stopCurrentAudio();

      const audio = new Audio(blobUrl);
      globalAudioRef.value = audio;
      currentAudioKey.value = audioUrl;

      this.$emit("audio-start", key);

      audio.addEventListener("ended", () => {
        this.$emit("audio-ended", key);
        globalAudioRef.value = null;
        currentAudioKey.value = null;
        URL.revokeObjectURL(audio.src);
      });

      audio.addEventListener("pause", () => {
        this.$emit("audio-pause", key);
      });

      try {
        await audio.play();
        this.$emit("audio-playing", key);
      } catch (error) {
        console.error("Error playing audio:", error);
        const storageKey = this.generateStorageKey(audioUrl);
        await removeAudioItem(storageKey);
      }
    },
  },
});
