
import { Options, Prop, Vue } from "vue-property-decorator";
import FscAudioPlayer from "@/components/FscAudioPlayer.vue";
import { setupLongPressGesture } from "@/utils/gestureUtils";
import { IQuestion } from "@/interfaces/IQuestion";

@Options({
  components: { FscAudioPlayer },
})
export default class TitleAndAdditionalText extends Vue {
  private readonly LONG_PRESS_DURATION = 500;

  @Prop({ required: true })
  public question!: IQuestion;

  private playAudio(key: string): void {
    const audioPlayer = this.$refs.audioPlayer as any;
    if (audioPlayer) {
      audioPlayer.playAudio(key);
    }
  }

  public mounted() {
    const titleElement = this.$refs.titleGesture as HTMLElement;
    const additionalTextElement = this.$refs.additionalTextGesture as HTMLElement;

    if (titleElement) {
      setupLongPressGesture(titleElement, this.LONG_PRESS_DURATION, () => {
        this.playAudio("title");
      });
    }

    if (this.question.additionalText && additionalTextElement) {
      setupLongPressGesture(additionalTextElement, this.LONG_PRESS_DURATION, () => {
        this.playAudio("additionalText");
      });
    }
  }
}
