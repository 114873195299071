import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML", "title"]
const _hoisted_3 = ["innerHTML", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FscAudioPlayer = _resolveComponent("FscAudioPlayer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_component_FscAudioPlayer, {
      ref: "audioPlayer",
      question: _ctx.question,
      key: _ctx.question.id
    }, null, 8, ["question"])),
    _createElementVNode("div", {
      class: "question-title mb-2",
      style: {"white-space":"pre-line"},
      innerHTML: _ctx.question.textUnderMedia
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "question-title mb-2",
      style: {"white-space":"pre-line","cursor":"help"},
      innerHTML: _ctx.question.title,
      ref: "titleGesture",
      title: _ctx.$t('text_to_speech.press_and_hold_to_listen')
    }, null, 8, _hoisted_2),
    (_ctx.question.additionalText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "question-title mb-4",
          style: {"white-space":"pre-line","cursor":"help"},
          innerHTML: _ctx.question.additionalText,
          ref: "additionalTextGesture",
          title: _ctx.$t('text_to_speech.press_and_hold_to_listen')
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}