import axios, { AxiosError, AxiosRequestConfig } from "axios";
import UserService from "@/services/UserService";
import { createToasted } from "@/plugins/Vue3ToastedPlugin";
import jwtDecode from "jwt-decode";
import i18n from "@/i18n";
import moment from "moment";
import StorageService from "@/services/StorageService";
import AxiosCache from "@/utils/AxiosCache";

const toasted = createToasted({
  duration: 5000,
  theme: "outline",
  iconPack: "callback",
});

export const cacheableAxios = async () => {
  const _axiosInstance = AxiosCache.Instance;

  const _axios = await _axiosInstance._axios;

  _axios.interceptors.request.use((config: any) => {
    const token: string | null = UserService.getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token.replaceAll('"', "");
    }
    return config;
  });

  return _axios;
};

export const $axios = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
});

export const $fileAxios = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
});

$fileAxios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token: string | null = UserService.getToken();
  if (token) {
    config.headers["Authorization"] = "Bearer " + token.replaceAll('"', "");
  }
  return config;
});

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
});

console.info("Backend URL:", instance.defaults.baseURL, process.env.VUE_APP_BACKEND_BASE_URL);

let refreshTokenPromise: null | Promise<any>;

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token: string | null = UserService.getToken();

  if (i18n.global.locale) {
    config.headers["Accept-Language"] = String(i18n.global.locale).toLowerCase() + "-" + String(i18n.global.locale).toUpperCase();
  }

  if (token) {
    const { exp } = jwtDecode<{ exp: number }>(token);
    const refreshToken = UserService.getRefreshToken();

    const now = moment();
    const tokenIsExpired = moment.unix(exp).diff(now) < 1;

    if (tokenIsExpired) {
      try {
        if (!refreshTokenPromise) {
          refreshTokenPromise = $axios.post<{ token: string; refreshToken: string }>("/refresh-token", { refreshToken }).then((response) => {
            const token = response.data.token?.replaceAll('"', "");
            StorageService.setToStorage("token", token);
            UserService.setRefreshToken(response.data.refreshToken);
            refreshTokenPromise = null;
            return token;
          });
        }

        return refreshTokenPromise?.then((token) => {
          if (config.headers) config.headers["Authorization"] = "Bearer " + token;
          return config;
        });
      } catch (exception) {
        console.error(exception);
      }
    }

    config.headers["Authorization"] = "Bearer " + token.replaceAll('"', "");
  }

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error instanceof axios.Cancel) {
      return Promise.reject(error);
    }

    // Handle Token Timeouts
    if (error.response?.status === 401) {
      UserService.clearUser();
      window.location.reload();

      // Handle Unprocessable Entity
    } else if (error.response?.status === 422) {
      const { globalErrors, fieldErrors } = error.response.data;

      if (fieldErrors.length) {
        const fieldError = `${fieldErrors[0].fieldName} ${fieldErrors[0].error}`;
        toasted.error(fieldError);
      }
      toasted.error(globalErrors[0]);

      // Handle any error
    } else if (error.response?.status === 404) {
      // Do not show modal for 404 until we develop solution for standard-question-explanation existence
      return Promise.reject(error);
    } else {
      // if (!(error.response?.data instanceof ArrayBuffer)) {
      toasted.error(error.response?.data.message || error.message);
      //}
    }

    return Promise.reject(error);
  }
);

export default instance;
