import localForage from "localforage";
// @ts-ignore
import memoryDriver from "localforage-memoryStorageDriver";

export const STORAGE_IMAGE_KEY = "drive_buzz_http_image_cache";
export const STORAGE_AUDIO_KEY = "drive_buzz_http_audio_cache";

// Define the memory driver if not already defined
const initMemoryDriver = async () => {
  if (!localForage.supports(memoryDriver._driver)) {
    await localForage.defineDriver(memoryDriver);
  }
};

// Create LocalForage instances for audio and image storage
const createForageStore = (name: string) =>
  localForage.createInstance({
    driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE, memoryDriver._driver],
    name,
  });

const forageAudioStore = createForageStore(STORAGE_AUDIO_KEY);
const forageImageStore = createForageStore(STORAGE_IMAGE_KEY);

// Common helper for removing items
const removeItem = async (store: LocalForage, key: string, prependBaseUrl = false) => {
  await initMemoryDriver();
  const finalKey = prependBaseUrl ? `${process.env.VUE_APP_BACKEND_BASE_URL}/${key}` : key;
  await store.removeItem(finalKey);
};

export const removeImageItem = async (key: string) => {
  await removeItem(forageImageStore, key, true);
};

export const removeAudioItem = async (key: string) => {
  await removeItem(forageAudioStore, key);
};

export const setAudioItem = async (key: string, value: any) => {
  await initMemoryDriver();
  await forageAudioStore.setItem(key, value);
};

export const getAudioItem = async (key: string): Promise<Blob | null> => {
  await initMemoryDriver();
  return await forageAudioStore.getItem<Blob>(key);
};
