import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fa-solid fa-2xl fa-book-open-cover"
}
const _hoisted_2 = {
  key: 1,
  class: "fa-thin fa-2xl fa-book-open-cover"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "pointer",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandle && _ctx.clickHandle(...args)))
  }, [
    (_ctx.active)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1))
      : (_openBlock(), _createElementBlock("i", _hoisted_2))
  ], 512)), [
    [_vShow, _ctx.question.hint]
  ])
}